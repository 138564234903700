import React from 'react';
import { useForm } from 'react-hook-form';
import PageLayout from 'components/PageLayout';
import Box from '@material-ui/core/Box'
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import axiosWithAuth from 'utils/axiosWithAuth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useGlobalLoading from 'hooks/useGlobalLoading';

function TwoStepVerification() {
  const { setIsLoading } = useGlobalLoading();
  const history = useHistory();
  const accountId = localStorage.getItem('accountId');
  const { register, handleSubmit, reset } = useForm();
  const submitCode = data => {
    setIsLoading(true);
    axiosWithAuth()
      .post(`/heat/two-step-verification/${accountId}`, data)
      .then(res => {
        console.log(res.data.message)
        toast.success('Successfully authorized account.')
        history.push('/customers')
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <PageLayout title='Two Step Verification'>
      <Box width='30%' display='flex' mt={2}>
        Please submit the code that was emailed to you by the HEAT system and submit.
      </Box>
      <Box width='10%' mt={1}>
        <form onSubmit={handleSubmit(submitCode)}>
          <Box>
            <TextField 
              name="code" 
              placeholder="verification code" 
              variant="outlined" 
              size="small" 
              inputRef={register} 
              className='w-full' 
            />
          </Box>
          <Box mt={1}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </PageLayout>
  );
};

export default TwoStepVerification;